<template>
    <v-app style="background-color: lightgray;">
         <v-toolbar  :style="appBarStyle" density="compact" >
            
            <v-app-bar-nav-icon @click.stop="drawer = !drawer">
            </v-app-bar-nav-icon>
            <v-spacer></v-spacer>

            <v-toolbar-title class="logo header-toobar-title text-center">
              <strong>REBELLE</strong>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-app-bar-nav-icon 
            size="large"
            color="purple-darken-2"
            icon="mdi-cart"
            @click.stop="cartDrawer= !cartDrawer"
            ></v-app-bar-nav-icon>
          </v-toolbar>

           <v-toolbar style="z-index: 2;margin-top: 42px;color:white" class="light-gray-background" density="compact" >
            <v-spacer></v-spacer>
               <v-chip variant="outlined"> Silver:{{ silverrate }}
                <v-tooltip
                activator="parent"
                location="bottom"
                >Current Silver Price is {{ silverrate }} per gram</v-tooltip>
            </v-chip>
             <v-spacer></v-spacer>
          </v-toolbar>

          <v-toolbar style="z-index: 2;margin-top: 0px;background-color: transparent;color:white" >
        <v-spacer></v-spacer>

      <!-- WeChat Icon -->
      <v-btn icon @click="handleIconClick('WeChat')">
        <v-icon>mdi-wechat</v-icon>
      </v-btn>

      <!-- WhatsApp Icon -->
      <v-btn icon @click="handleIconClick('WhatsApp')"  :disabled="isWhatsAppDisabled">
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>

      <!-- Instagram Icon -->
      <v-btn icon @click="handleIconClick('Instagram')">
        <v-icon>mdi-instagram</v-icon>
      </v-btn>

      <!-- Meta (Facebook) Icon -->
      <v-btn icon @click="handleIconClick('Meta')"  :disabled="isMetaDisabled">
        <v-icon>mdi-facebook</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
      </v-toolbar>
         
      <v-carousel 
      style="position: absolute; top: 0; left: 0; z-index: 1; width: 100%;height:800px;background-color: lightgray;" 
      :show-arrows="false"
      hide-delimiter-background="true"
      class="rounded-xl"  
      width="1200" cycle>
                  <template  v-for="(post) in posts"  :key="post">
                <v-carousel-item
                  :src='"http://154.8.177.176/Image/"+post.picture'
                  cover>
                  <div
                    class="d-flex fill-height justify-center"
                    style="margin-top: 50px"
                  >
                    <div style="color:white;font-size:36px;font-family:Baskerville">{{post.title}}</div>
                  </div>
                </v-carousel-item>
            </template>
          </v-carousel>
       <v-navigation-drawer
        v-model="drawer"
        expand-on-hover
        :rail="rail"
        permanent
        @click="rail = false"
      >
       <v-card flat rounded="0" >
        <v-list-item
          prepend-avatar="https://randomuser.me/api/portraits/men/85.jpg"
          title="John Leider"
          nav
        >
        </v-list-item>
  
        <v-divider></v-divider>
  
        <v-list density="compact" nav>
          <v-list-item
            prepend-icon="mdi-home-city"
            title="Home"
            value="home"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-account"
            title="My Account"
            value="account"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-account-group-outline"
            title="Users"
            value="users"
          ></v-list-item>
        </v-list>
       </v-card>
      
        </v-navigation-drawer>
        <!-- <v-navigation-drawer
        v-model="cartDrawer"
        permanent
        location="right"
      >

<template v-for="(user, index) in users" :key="index">
    <v-card   max-width="300" >
       <v-list density="compact">

          <v-list-item
            
            subtitle="Logged in"
          >
          title={{ accountName }}

        </v-list-item>

        <v-divider></v-divider>

        <template  v-for="(selection, index) in selections"  :key="index" >
        <v-list-item  density="compact" nav>    
          <img class="rounded-sm" 
                    style="height:30px;width:30px" 
                    :src='"http://154.8.177.176/Image/"+selection.picture'>
                    {{ selection.barcode }}
                    {{ selection.description }}
                    {{ selection.quantity }}           
                    <template v-slot:append>
                        <v-icon color="error" icon="mdi-delete" v-on:click="greet"></v-icon>
                    </template>
       </v-list-item>
        </template>
       </v-list>
    </v-card>
    <v-card>
      <v-btn icon
      @click="toggleCard"
      class="rotate-button">
    <v-icon :class="{'rotate-down': showCard, 'rotate-up': !showCard}">
          mdi-chevron-down
    </v-icon>
    </v-btn>
    <div>
    <Suspense>
    <template #default>
     <v-slide-y-transition>
    <v-card v-if="showCard" 
      class="mx-auto pa-6 pb-8"
      elevation="8"
      max-width="386"
      rounded="lg"
    >
    <v-sheet
    v-if="login"
    max-width="600"
    rounded="lg"
    width="100%"
    class="pa-4 text-center mx-auto"
  >
    <v-icon
      class="mb-5"
      color="success"
      icon="mdi-checkbox-marked-circle-outline"
      size="112"
    ></v-icon>

    <h2 class="text-h5 mb-6">Welcome! {{ accountName }}</h2>

    <p class="mb-4 text-medium-emphasis text-body-2">
      To see a report on this reconciliation, click <a href="#" class="text-decoration-none text-info">View reconciliation report.</a>

      <br>

      Otherwise, you're done!
    </p>

    <v-divider class="mb-4"></v-divider>

    <div class="text-end">
      <v-btn
        v-if="admin"
        class="text-none"
        color="warning"
        rounded
        variant="flat"
        width="90"
        @click="this.$router.push('/DashBoard')"
      >
      Dashboard
      </v-btn>
      <v-btn
        class="text-none"
        color="success"
        rounded
        variant="flat"
        width="90"
        @click="logOut"
      >
      Log Out
      </v-btn>
    </div>
  </v-sheet>
    <form  v-else ref="form" @submit.prevent="handleSubmit()">
      <div class="text-subtitle-1 text-medium-emphasis">Account</div>

      <v-text-field
        v-model="accountName"
        density="compact"
        placeholder="Email address"
        prepend-inner-icon="mdi-email-outline"
        variant="outlined"
      ></v-text-field>

      <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
        Password
     
        <a
          class="text-caption text-decoration-none text-blue"
          href="#"
          rel="noopener noreferrer"
          target="_blank"
        >
          Forgot login password?</a>
      </div>

      <v-text-field
        v-model="password"
        :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
        :type="visible ? 'text' : 'password'"
        density="compact"
        placeholder="Enter your password"
        prepend-inner-icon="mdi-lock-outline"
        variant="outlined"
        @click:append-inner="visible = !visible"
      ></v-text-field>

      <v-card
        class="mb-12"
        color="surface-variant"
        variant="tonal"
      >

        <v-card-text class="text-medium-emphasis text-caption">
          Warning: After 3 consecutive failed login attempts, you account will be temporarily locked for three hours. If you must login now, you can also click "Forgot login password?" below to reset the login password.
        </v-card-text>
      </v-card>

      <v-btn
         type="submit"
        :disabled="loading"
        :loading="loading"
        block
        class="mb-8"
        color="blue"
        size="large"
        variant="tonal"
        
      >
        Log In
      </v-btn>

      <v-card-text class="text-center">
        <a
          class="text-blue text-decoration-none"
          href="#"
          rel="noopener noreferrer"
          target="_blank"
        >
          Sign up now <v-icon icon="mdi-chevron-right"></v-icon>
        </a>
      </v-card-text>
    </form>
    </v-card>
    </v-slide-y-transition>
    </template>
    <template #fallback>
        <v-skeleton-loader
          v-if="loading == true" :loading="loading" transition="fade-transition"
          class="mx-auto border"
          max-width="300"
          type="card-avatar, actions"
        ></v-skeleton-loader>
    </template>
    </Suspense>
    </div>
    </v-card>
  </template>
        </v-navigation-drawer> -->

        <v-card flat rounded="0" >
         
          <v-toolbar class="light-gray-background"  density="compact" >
          <v-tabs
          v-model="tab"
          bg-color="#FFFFFF00"
          >
          <v-tab value="one">Products</v-tab>
          <!-- <v-tab value="two">Logs</v-tab> -->
          <v-tab value="two">About us</v-tab>
          </v-tabs>
        </v-toolbar>
        </v-card>  

        <v-main  style="margin-top: 600px;" class="light-gray-background">
        <v-window v-model="tab" >
        <v-overlay v-model = "isLoading" style="padding:25% 45% 45% 45%" > 
           <div class="text-center">
          <v-progress-circular
          :size="70"
           indeterminate
           color="primary"
           style="margin:50% auto"
        ></v-progress-circular>
        </div> 
        </v-overlay>
      <v-window-item style="min-height: 1000px; background-color: lightgray;" value="one">
        <!-- <v-text-field
            variant="outlined
            clearable
            label="Search ..."
            class="mx-auto capsule-text-field"
            style="max-width: 480px"
            filled
            prepend-inner-icon="mdi-magnify"
            dense
            solo
            flat
            background-color="grey lighten-4"
            v-model=keyword
            @keyup.enter="getItems"
          >   </v-text-field> -->
        <v-row>
          <v-col cols="12" sm="4">
   
    </v-col>
  </v-row>
      <v-container styel=" margin-top: 400px" fluid>
      <v-row class="mt-4" key="card">
        <v-col
        v-for="(item,index) in items" 
          :key="index" 
          cols="6"
         sm="4" 
         md="3" 
         lg="2" 
         class="d-flex"
        >
          <v-card  elevation="0" class="fixed-card-width"> 
             <v-carousel
               v-model="item.activeIndex"
            @mouseenter="startAutoSlide(index)"
            @mouseleave="stopAutoSlide(index)"
             height="228px"
             cycle
        :interval="3000"
        hide-delimiters
         :show-arrows="false" >
        <template v-for="(image, index) in item.picture.split(',')" >
        <v-carousel-item  
        :src='"http://154.8.177.176/Image/"+image.trim()'
        @click="zoomItem(image)"
        >
        <v-dialog v-model="zoomDialog" max-width="599px">
        <v-sheet>
          <v-img :src='"http://153.8.177.176/Image/"+zoomedImage.trim()' aspect-ratio="1.5">
          </v-img>
        </v-sheet>
      </v-dialog>
        </v-carousel-item>
 
        </template>
       </v-carousel>
      
    <v-card-title style="white-space:normal; font-family : American Typewriter;font-size: 15px;display: flex;word-wrap: break-word;">{{ item.title }}</v-card-title>
     <v-card-subtitle  style="font-family:American Typewriter" class="pb-0">{{ item.description }}</v-card-subtitle>
     <v-card-subtitle   style="font-family:American Typewriter" class="pb-0">{{ item.barcode }}</v-card-subtitle>
     <v-card-text style="font-family:American Typewriter" class="price">
       <strong>¥{{ item.price }}</strong>
     </v-card-text>
   </v-card>

     
         </v-col>
      </v-row>
      <v-btn
      v-if="items.length > 0"
        :disabled="loading"
        :loading="loading"
        block
        class="text-none mb-4"
        color="#A7FFEB"
        size="default"
        variant="flat"
        @click="loadmore"
      >
        {{ loadingMoreButton }}
      </v-btn>

      </v-container>
 
        </v-window-item>

        
        <v-window-item value = "two">
                      <v-card
    class="mx-auto"
    color="#26c6da"
    theme="dark"
    max-width="400"
    prepend-icon="mdi-twitter"
    title="Twitter"
  >
    <template v-slot:prepend>
      <v-icon size="x-large"></v-icon>
    </template>

    <v-card-text class="text-h5 py-2">
      "Since 2014, Rebelle 925 silver jewelry company 
      has been dedicate to present our beloved customers with fashionable and deliciated jewelries. 
      As a business partner, we always offer a reliable products quality with competitive price, 
      and a promised delivery time."
    </v-card-text>

    <v-card-actions>
      <v-list-item class="w-100">
        <template v-slot:prepend>
          <v-avatar
            color="grey-darken-3"
            image="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
          ></v-avatar>
        </template>

        <v-list-item-title>Zheng Sizhi</v-list-item-title>

        <v-list-item-subtitle>Director</v-list-item-subtitle>
      </v-list-item>
    </v-card-actions>
  </v-card>

        </v-window-item>
        </v-window>
</v-main>
</v-app>
</template>

<script>
import axios from 'axios'

export default {
      data: () => ({
         zoomDialog: false,
        zoomedImage: null,
         isWhatsAppDisabled: true, 
          isMetaDisabled: true, 
        loadingMoreButton:"LOAD MORE",
        showCard: false,
        cartDrawer:false,
        drawer: false,
        rail: true,
        item: {
          "id":0,
            "description":"",                        
            "barcode":"",                         
            "picture":"",                     
            "color":"",                     
            "article":"", 
            "stock":"0",                 
            "labour":"0",                  
            "profit":"0",               
            "finding":"0",  
            "netweight":"0",
            "grossweight":"0",
            "company":"",
            "remark":"",
            "title":"",
            "detailimages":"",
        },
        items:[],
        silverrate:"??",
        selections:[],
        // users:JSON.parse(localStorage.getItem('users')),
        posts:[],
        accountName:"",
        // role:"",
        password:"",
        keyword : "",
        page: 0,
        count : 0,
        loading:false,
        isLoading:false,
        login:false,
        sortedListItems : [],
        detailimages:[],
        backgroundColor: 'rgba(255, 255, 255, 0)', // Initial transparent background
        zIndex: 2, // z-index for stacking order
        position: 'fixed', // Fixed position
      }),

      beforeMount(){

      this.fetchLastRate()

      // if(this.users.role=="admin"){
      //   this.login=true
      //   this.admin=trueD
      // }
      // if(this.admin==true){

      //   this.$router.push('/DashBoard')

      // }
   

      },
computed: {
    appBarStyle() {
      return {
        backgroundColor: this.backgroundColor,
        zIndex: 3,
        position: this.position,
        transition: 'background-color 0.3s ease', // Smooth transition for background color
      };
    },
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll);
      this.getPost(),
      this.getItems()
  },
beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
      methods:{
        handleScroll() {
      const scrollTop = window.scrollY;
      // Change the background color based on scroll position
      if (scrollTop > 100) {
        this.backgroundColor = 'rgba(255, 255, 255, 1)'; // Opaque background
      } else {
        
        this.backgroundColor = 'rgba(255, 255, 255, 0)'; // Transparent background
      }
    },
        startAutoSlide(index) {
      // Start auto-slide when the mouse enters
      this.items[index].interval = setInterval(() => {
        const carousel = this.items[index];
        carousel.activeIndex =
          (carousel.activeIndex + 1) % 1000;
      }, 1000); // Slide every 1 second
    },
    stopAutoSlide(index) {
      // Stop auto-slide when the mouse leaves
      clearInterval(this.items[index].interval);
      this.items[index].interval = null;
    },
         zoomItem(image) {
      this.zoomedImage = image; // Set the clicked item to be displayed in the dialo
      this.zoomDialog = true; // Open the dialog
    },
        fetchLastRate(){
            axios.get("http://154.8.177.176/fetchLastRate")
               .then(
                   response => this.silverrate= response.data
               )
        },

        getPost(){
          this.isLoading=true
            axios.get("http://154.8.177.176/postSliders")
            .then(
                response => {this.posts = response.data
            }
            )
            setTimeout(() => (    
            this.isLoading = false
            ), 3000)
        },

        getItems(){
           this.isLoading=true
            this.loading=true
            setTimeout(() => {
            axios.post('http://154.8.177.176/searchrebelle', {
            keyword: this.keyword,
            page: this.page
        }, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(response => {
          if(response.data==""){
            this.loading = false
            this.loadingMoreButton="no more items found!"
          }else if(response.data!=""){
            response.data.map((e) => {
             
              if(e.detailimages == null || e.detailimages == ""){
              
              }else{
                e.picture = e.picture+","+e.detailimages
         
             }
              e.price =  JSON.stringify(((parseFloat(e.labour)+
               parseFloat(e.finding)+
               parseFloat(e.netweight)*
               parseFloat(this.silverrate)).toFixed(2)*4))
              
          this.items.push(e)
        
          })
           this.items = this.items.map(carousel => ({
      ...carousel,
      activeIndex: 0, // Initialize active index
      interval: null,  // Initialize interval as null
    }));
          this.loading = false
          this.loadingMoreButton="LOAD MORE"
          }
     
         })
      })
    },
    loadmore () {
      // Perform API call
       this.count ++

       this.loading=true

        setTimeout(() => {
             axios.post("http://154.8.177.176/searchrebelle",
            {
                keyword: this.keyword,
                page: this.count
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        )
         .then(response => {
          if(response.data==""){
            this.loading = false
            this.loadingMoreButton="no more items found!"
          }else if(response.data!=""){
            response.data.some((e) => {
              if(e.detailimages == null || e.detailimages == ""){
              
            }else{
              e.picture = e.picture+","+e.detailimages
         
           }
             e.price =  JSON.stringify(((parseFloat(e.labour)+
               parseFloat(e.finding)+
               parseFloat(e.netweight)*
               parseFloat(this.silverrate)).toFixed(2)*4)) 
          this.items.push(e)
         
          })
          this.loading = false
          this.loadingMoreButton="LOAD MORE"
          }
     
         })

        }, 3000)

    },
     toggleCard() {
      this.showCard = !this.showCard; // Toggles the card's visibility
    },
    sortArrayBy(property) {
      this.items.sort((a, b) => {
        if (a[property] < b[property]) return -1;
        if (a[property] > b[property]) return 1;
        return 0;
      });
    },
     handleIconClick(platform) {
      if (platform === 'WhatsApp' && this.isWhatsAppDisabled) return;
      if (platform === 'Meta' && this.isMetaDisabled) return;
      console.log(`Clicked ${platform} icon`);
      // Add logic to navigate or perform an action
    },
      //   async handleSubmit(){
      //   this.loading = true
  
      //   await axios.post('http://154.8.177.176/visitorsLogin', {
      //       uname: this.accountName,
      //       pwd: this.password
      //   }, {
      //       headers: {
      //       'Content-Type': 'multipart/form-data'
      //      }
      //   })
      //   .then(
      //     response => {
                  
      //       this.users= response.data,

            
      //       this.role = this.users.role,
            
      //              localStorage.setItem('users', JSON.stringify(response.data));  
                   
      //              if(this.role=="admin"){
                    
      //               this.admin = true
                  
      //               this.$router.push('/DashBoard')

      //               }
      //       }          
      //   )

      //   setTimeout(() => (  
      //       this.login = true,      
      //       this.loading = false
      //       ), 2000)
      //  },

       logOut () {   
        this.login = false
      },
    }

    }

 
</script>
<style scoped>

body{
    font-family: "American Typewriter", Courier, monospace;
}
.logo{
    font-family: "Baskerville";
}
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
  }
.rotate-button {
  transition: transform 0.3s ease;
}
.rotate-up {
  transform: rotate(0deg);
}
.rotate-down {
  transform: rotate(180deg);
}

@media (max-width: 599px) {
  .fixed-card-width {
    width: 100%; /* Make cards take full width on very small screens */
  }
}
.fixed-card-width {
  width: 150px; /* Maximum width of the card */ width: 100%;
  max-width: 100%; /* Ensures the card takes full column width */
  margin-bottom: 16px; /* Space between rows */
}
.header-toobar-title{
  width: 240px; 
  min-width: 240px;
}
.capsule-text-field .v-input__control {
  border-radius: 50px; /* Creates a capsule shape */
}

.capsule-text-field .v-input__slot {
  border-radius: 50px; /* Ensure the inner slot is also rounded */
}

.capsule-text-field .v-text-field__append-inner {
  border-radius: 50px; /* Rounds the clear button */
}

.v-carousel__controls {
  display: none !important;
}

.light-gray-background {
  background-color: transparent;
}

</style>