<template>
  <PageEntry />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import PageEntry from '../components/PageEntry/PageEntry.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    PageEntry,
  },
});
</script>
