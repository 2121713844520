import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import print from 'vue3-print-nb'
import JsBarcode from 'jsbarcode'
import cors from 'cors'

loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(cors)
  .use(print)
  .use(JsBarcode)
  .mount('#app')
