import { createRouter, createWebHashHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
  ,
  {
    path: '/DashBoard',
    name: 'DashBoard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/DashBoard/DashBoard.vue'),
    meta: {
      requiresAuth: true
    }
  }
  ,
  {
    path: '/InvoiceView',
    name: 'InvoiceView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/DashBoard/InvoiceView.vue')
  }
  ,
  {
    path: '/CurrierBill',
    name: 'CurrierBill',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/DashBoard/CurrierBill.vue')
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  var isLoggedIn = false;
  if(localStorage!=null){
    isLoggedIn = true
  }
  if (to.meta.requiresAuth && !isLoggedIn) {
    next('/home'); 
  } else {
    // alert(to.meta.requiresAuth+"    "+isLoggedIn)
    next();
  }
});


export default router
